import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';

const CategoryStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 4rem;
  a {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0 1rem;
    align-items: center;
    padding: 5px;
    background: var(--green);
    border-radius: 2px;
    
    &[aria-current='page'] {
      background: var(--pink);
    }
  }
 @media (max-width: 709px) {
    margin-botton: 2 rem;
  }
`;

function countItemsInCategory (items) {
  // Return the pizzas with counts
  const counts = items
    .map((items) => items.category)
    .flat()
    .reduce((acc, category) => {
      // check if this is an existing topping
      const existingCategory = acc[category.id];
      if (existingCategory) {
        // console.log('Existing Category', existingCategory.name);
        //  if it is, increment by 1
        existingCategory.count += 1;
      } else {
        // console.log('New Category', category.name);
        // otherwise create a new entry in our acc and set it to one
        acc[category.id] = {
          id: category.id,
          name: category.name,
          count: 1,
        };
      }
      return acc;
    }, {});
  // sort them based on their count
  const sortedCategory = Object.values(counts).sort(
    (a, b) => b.count - a.count
  );
  return sortedCategory;
}

export default function CategoryFilter ({ activeCategory }) {
  // Get a list of all the toppings
  // Get a list of all the Pizzas with their toppings
  const { category, items } = useStaticQuery(graphql`
    query {
      category: allSanityCategory (
        sort: { fields: description, order: ASC }
      ) {
        nodes {
          name
          id
        }
      }
     items: allSanityItems {
        nodes {
          category {
            name
            id
          }
        }
      }
    }
  `);
  // Count how many pizzas are in each topping
  const categoryWithCounts = countItemsInCategory(items.nodes);

  // Loop over the list of toppings and display the topping and the count of pizzas in that topping
  // Link it up.. ...  . . .
  return (
    <CategoryStyles>
      <Link to="/items">
        <span className="name">All</span>
      </Link>
      {categoryWithCounts.map((category) => (
        <Link
          to={`/category/${category.name}`}
          key={category.id}
          className={category.name === activeCategory ? 'active' : ''}
        >
          <span className="name" id={category.name.replace(/\s/g, "")}>{category.name}</span>
        </Link>
      ))}
      {/* <br />
      <img id="hibachi" src="https://github.com/Mary-Tyler-Moore/readme-gifs/blob/master/category/Hibachi%2520Entrees.png?raw=true" /> */}
    </CategoryStyles>
    // <CategoryStyles>
    //   {categoryWithCounts.map((category) => (
    //     <Link to={`/category/${category.name}`} key={category.id}>
    //       <span className="name">{category.name}</span>
    //       <span className="count">{category.count}</span>
    //     </Link>
    //   ))}
    // </CategoryStyles>
  );
}