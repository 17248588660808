// import React from 'react';
// import { graphql } from 'gatsby';
// import ItemsList from '../components/ItemsList';
// import CategoryFilter from '../components/CategoryFilter';
// import Nav from '../components/Nav';
// import CatImages from '../components/CatImages';

// export default function ItemsPage ({ data, pageContext }) {
//   const items = data.items.nodes;
//   return (
//     <>
//       <Nav />
//       <CategoryFilter activeCategory={pageContext.category} />
//       <h1 class="center" >{pageContext.category}</h1>
//       <br />
//       <CatImages />
//       <br />
//       <ItemsList items={items} />
//     </>
//   );
// }

// export const query = graphql`
//   query ItemsQuery2($categoryRegex: String) {
//   items: allSanityItems (
//     filter: { category: { elemMatch: { name: { regex: $categoryRegex } } } }
//     sort: { fields: _createdAt, order: ASC } 
//   ) {
//     nodes {
//       name
//       _createdAt
//       id
//       price
//       description
//       slug {
//         current
//       }
//       category {
//         id
//         name
//       }
//       image {
//         asset {
//           fluid(maxWidth: 400) {
//             ...GatsbySanityImageFluid
//           }
//         }
//       }
//     }
//   }
// }
// `;

import React from 'react';
import { graphql } from 'gatsby';
import ItemsList from '../components/ItemsList';
import CategoryFilter from '../components/CategoryFilter';
import Nav from '../components/Nav';
import FadeIn from '../utils/FadeIn';

export default function ItemsPage ({ data, pageContext }) {
  const items = data.items.nodes;
  const context = pageContext.category;
  console.log(context);



  // return (
  // <>
  //   <Nav />
  //   <CategoryFilter activeCategory={pageContext.category} />
  //   <h1 class="center" >{pageContext.category}</h1>
  //   <br />
  //   <img id="lunchbento" src="https://orchidmenu.com/assets/images/orchid-menu-logo-2.png" />
  //   <br />
  //   <ItemsList items={items} />
  // </>
  // );
  if (context == "Appetizers") {
    return (
      <>
        <Nav />
        <br />
        <CategoryFilter activeCategory={pageContext.category} />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <FadeIn>
          <div class="inner">
            <img id="appetizers" src="https://cdn.sanity.io/images/pda0uygu/production/c8b95b9de1d99795b950a69535ee60fc8e1300fd-4032x3024.jpg?w=1000" />
          </div>
        </FadeIn>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  // else if (context == "Beer") {
  //   return (
  //     <>
  //       <Nav />
  //       <br />
  //       <CategoryFilter activeCategory={pageContext.category} />
  //       <h1 class="center" >{pageContext.category}</h1>
  //       <br />
  //       <FadeIn>
  //         <div class="inner">
  //           <img id="beer" src="https://github.com/Mary-Tyler-Moore/readme-gifs/blob/master/category/Beer.png?raw=true" />
  //         </div>
  //       </FadeIn>
  //       <br />
  //       <br />
  //       <ItemsList items={items} />
  //     </>
  //   );
  // }
  // else if (context == "Combinations") {
  //   return (
  //     <>
  //       <Nav />
  //       <br />
  //       <CategoryFilter activeCategory={pageContext.category} />
  //       <h1 class="center" >{pageContext.category}</h1>
  //       <br />
  //       <FadeIn>
  //         <div class="inner">
  //           <img id="combinations" src="https://github.com/Mary-Tyler-Moore/readme-gifs/blob/master/category/Combinations.png?raw=true" />
  //         </div>
  //       </FadeIn>
  //       <br />
  //       <br />
  //       <ItemsList items={items} />
  //     </>
  //   );
  // }
  else if (context == "Cooked Maki Sushi") {
    return (
      <>
        <Nav />
        <br />
        <CategoryFilter activeCategory={pageContext.category} />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <FadeIn>
          <div class="inner">
            <img id="cookedmakisushi" src="https://cdn.sanity.io/images/pda0uygu/production/3bd1fa293ee6f328c71c689814bc23731547f5d1-1500x1125.jpg?w=1000" />
          </div>
        </FadeIn>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  // else if (context == "Desserts") {
  //   return (
  //     <>
  //       <Nav />
  //       <br />
  //       <CategoryFilter activeCategory={pageContext.category} />
  //       <h1 class="center" >{pageContext.category}</h1>
  //       <br />
  //       <FadeIn>
  //         <div class="inner">
  //           <img id="desserts" src="https://github.com/Mary-Tyler-Moore/readme-gifs/blob/master/category/Desserts.png?raw=true" />
  //         </div>
  //       </FadeIn>
  //       <br />
  //       <br />
  //       <ItemsList items={items} />
  //     </>
  //   );
  // }
  else if (context == "Hibachi Entrees") {
    return (
      <>
        <Nav />
        <br />
        <CategoryFilter activeCategory={pageContext.category} />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <FadeIn>
          <div class="inner">
            <img id="hibachientrees" src="https://cdn.sanity.io/images/pda0uygu/production/0065aa9fd76d0ac6ac23e47f78eef545f2a2b9e7-4032x3024.jpg?w=1000" />
          </div>
        </FadeIn>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  // else if (context == "Light Lunches") {
  //   return (
  //     <>
  //       <Nav />
  //       <br />
  //       <CategoryFilter activeCategory={pageContext.category} />
  //       <h1 class="center" >{pageContext.category}</h1>
  //       <br />
  //       <FadeIn>
  //         <div class="inner">
  //           <img id="lightlunches" src="https://github.com/Mary-Tyler-Moore/readme-gifs/blob/master/category/Light_Lunches.png?raw=true" />
  //         </div>
  //       </FadeIn>
  //       <br />
  //       <br />
  //       <ItemsList items={items} />
  //     </>
  //   );
  // }
  // else if (context == "Lunch Bento") {
  //   return (
  //     <>
  //       <Nav />
  //       <br />
  //       <CategoryFilter activeCategory={pageContext.category} />
  //       <h1 class="center" >{pageContext.category}</h1>
  //       <br />
  //       <FadeIn>
  //         <div class="inner">
  //           <img id="lunchbento" src="https://github.com/Mary-Tyler-Moore/readme-gifs/blob/master/category/Lunch_Bento.png?raw=true" />
  //         </div>
  //       </FadeIn>
  //       <br />
  //       <br />
  //       <ItemsList items={items} />
  //     </>
  //   );
  // }
  // if (context == "Nigiri Sushi") {
  //   return (
  //     <>
  //       <Nav />
  //       <br />
  //       <CategoryFilter activeCategory={pageContext.category} />
  //       <h1 class="center" >{pageContext.category}</h1>
  //       <br />
  //       <FadeIn>
  //         <div class="inner">
  //           <img id="nigirisushi" src="https://github.com/Mary-Tyler-Moore/readme-gifs/blob/master/category/Nigiri_Sushi.png?raw=true" />
  //         </div>
  //       </FadeIn>
  //       <br />
  //       <br />
  //       <ItemsList items={items} />
  //     </>
  //   );
  // }
  else if (context == "Noodle Dishes") {
    return (
      <>
        <Nav />
        <br />
        <CategoryFilter activeCategory={pageContext.category} />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <FadeIn>
          <div class="inner">
            <img id="noodledishes" src="https://cdn.sanity.io/images/pda0uygu/production/6a0a537ddee535a90d4098c5ed4f237a840559ed-4032x3024.jpg?w=1000" />
          </div>
        </FadeIn>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Pineapple Rice") {
    return (
      <>
        <Nav />
        <br />
        <CategoryFilter activeCategory={pageContext.category} />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <FadeIn>
          <div class="inner">
            <img id="pineapplerice" src="https://cdn.sanity.io/images/pda0uygu/production/2c6ec5df7088b6ee752951b3a671385397901725-4032x3024.jpg?w=1000" />
          </div>
        </FadeIn>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  else if (context == "Poke Bowl") {
    return (
      <>
        <Nav />
        <br />
        <CategoryFilter activeCategory={pageContext.category} />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <FadeIn>
          <div class="inner">
            <img id="pokebowl" src="https://cdn.sanity.io/images/pda0uygu/production/089fede22cb3119f28207c996214f70d9e7b4735-2131x1680.jpg?w=1000" />
          </div>
        </FadeIn>
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  if (context == "Raw Maki Sushi") {
    return (
      <>
        <Nav />
        <br />
        <CategoryFilter activeCategory={pageContext.category} />
        <h1 class="center" >{pageContext.category}</h1>
        <br />
        <FadeIn>
          <div class="inner">
            <img id="rawmakisushi" src="https://cdn.sanity.io/images/pda0uygu/production/1ddbfe92bca5e0c92c05434ed39f0e11a0f96371-3000x2250.jpg?w=1000" />
          </div>
        </FadeIn>
        <br />
        <br />
        <ItemsList items={items} />
      </>
    );
  }
  // else if (context == "Soft Drinks & Tea") {
  //   return (
  //     <>
  //       <Nav />
  //       <br />
  //       <CategoryFilter activeCategory={pageContext.category} />
  //       <h1 class="center" >{pageContext.category}</h1>
  //       <br />
  //       <FadeIn>
  //         <div class="inner">
  //           <img id="softdrinksandtea" src="https://github.com/Mary-Tyler-Moore/readme-gifs/blob/master/category/Soft_Drinks_And_Tea.png?raw=true" />
  //         </div>
  //       </FadeIn>
  //       <br />
  //       <br />
  //       <ItemsList items={items} />
  //     </>
  //   );
  // }
  // else if (context == "Soups & Salads") {
  //   return (
  //     <>
  //       <Nav />
  //       <br />
  //       <CategoryFilter activeCategory={pageContext.category} />
  //       <h1 class="center" >{pageContext.category}</h1>
  //       <br />
  //       <FadeIn>
  //         <div class="inner">
  //           <img id="soupsandsalads" src="https://github.com/Mary-Tyler-Moore/readme-gifs/blob/master/category/Soups_And_Salads.png?raw=true" />
  //         </div>
  //       </FadeIn>
  //       <br />
  //       <br />
  //       <ItemsList items={items} />
  //     </>
  //   );
  // }
  // else if (context == "Vegetarian") {
  //   return (
  //     <>
  //       <Nav />
  //       <br />
  //       <CategoryFilter activeCategory={pageContext.category} />
  //       <h1 class="center" >{pageContext.category}</h1>
  //       <br />
  //       <FadeIn>
  //         <div class="inner">
  //           <img id="vegetarian" src="https://github.com/Mary-Tyler-Moore/readme-gifs/blob/master/category/Vegetarian.png?raw=true" />
  //         </div>
  //       </FadeIn>
  //       <br />
  //       <br />
  //       <ItemsList items={items} />
  //     </>
  //   );
  // }
  // else if (context == "Wine") {
  //   return (
  //     <>
  //       <Nav />
  //       <br />
  //       <CategoryFilter activeCategory={pageContext.category} />
  //       <h1 class="center" >{pageContext.category}</h1>
  //       <br />
  //       <FadeIn>
  //         <div class="inner">
  //           <img id="wine" src="https://github.com/Mary-Tyler-Moore/readme-gifs/blob/master/category/Wine.png?raw=true" />
  //         </div>
  //       </FadeIn>
  //       <br />
  //       <br />
  //       <ItemsList items={items} />
  //     </>
  //   );
  // }
  else {
    return (
      <>
        <Nav />
        <br />
        <CategoryFilter activeCategory={pageContext.category} />
        <h1 class="center" >{pageContext.category}</h1>
        <br />

        <br />
        <ItemsList items={items} />
      </>
    );
  }
}

export const query = graphql`
  query ItemsQuery3($categoryRegex: String) {
  items: allSanityItems (
    filter: { category: { elemMatch: { name: { regex: $categoryRegex } } } }
    sort: { fields: _createdAt, order: ASC } 
  ) {
    nodes {
      name
      _createdAt
      id
      price
      description
      slug {
        current
      }
      category {
        id
        name
      }
    }
  }
}
`;